import { Button, Form, Input, message } from 'antd';
import React, { Component } from 'react';
import { sendSessionAnalytics } from 'untils/tracking';
import store from '../../redux/store';
import { facebookBoundEmail, verificationCode } from '../../services/index';
import {
  getLocalStorage,
  getSessionStorage,
  regularCheck,
  saveUserInfo,
  setLocalStorage,
} from '../../untils/auth';

export default class BoundFacebook extends Component {
  state = {
    active: true,
    time: 60,
  };
  formRef = React.createRef();
  onFinish = (e) => {
    let { active } = this.state;
    if (active) {
      facebookBoundEmail({
        account: e.account,
        openId: this.props.openId,
        verifyCode: e.verifyCode,
        rcvEmailFlag: 1,
      }).then((res) => {
        if (res.code) {
          sendSessionAnalytics(res.data.userid);
          message.success('Binding success!');
          let user = {
            account: e.account,
            name: res.data.name,
            surName: res.data.surName,
            phone: res.data.phone,
            phoneCode: res.data.phoneCode,
            countryIso2: res.data.countryIso2,
            country: res.data.country,
            photo: res.data.photo,
            membershipCardType: res.data.membershipCardType,
            membershipShuttleNumber: res.data.membershipShuttleNumber,
          };
          saveUserInfo(user);
          store.dispatch({
            type: 'token',
            data: res.data.accessToken,
          });
          setLocalStorage('userId', res.data.userid);
          setLocalStorage('time', res.data.accessExpire + '000');
          // setLocalStorage('loginType', true);
          // openLoginPopup('close');
          this.props.changeLogin();
          // 弹出领卷成功
          if (res.data.newUser) {
            this.props.gainCoupon(true);
          }
          // 详情页
          if (getLocalStorage('details') !== null) {
            this.props.history.push('/payDetails');
          }

          if (getSessionStorage('chaData')) {
            store.dispatch({
              type: 'charterQuoteSumbit',
            });
          }

          if (getSessionStorage('eptData')) {
            store.dispatch({
              type: 'emptyLegSumbit',
            });
          }
        } else {
          if (res.msg.indexOf('Invalid code') > -1) {
            return message.error('Incorrect verification code, please try again');
          } else if (res.msg.indexOf('The bound account already exists') > -1) {
            return message.error(
              'This account already exists, please change your email and try again'
            );
          } else {
            return message.error('Binding failure!');
          }
        }
      });
    }
  };
  // 倒计时
  timing = (n) => {
    let clear;
    let time = n;
    clear = setInterval(() => {
      time === 0 ? clearInterval(clear) : time--;
      this.setState({
        time,
      });
    }, 1000);
  };
  // 发送验证码
  resendCode = () => {
    let { time } = this.state;
    if (time !== 60 && time !== 0) {
      return;
    }
    let { account } = this.formRef.current.getFieldValue();
    if (!account) {
      return message.warning('Please fill in your email address first!');
    }
    if (account && !regularCheck('email', account)) {
      return message.error('Please enter a valid email address');
    }
    verificationCode(account).then((res) => {
      if (res.code) {
        message.success('Send a success!');
        this.timing(60);
      } else {
        message.error(res.msg);
      }
    });
  };
  render() {
    let { active, time } = this.state;
    return (
      <div className="forgot-password-form">
        <p className="facebook-title">
          {/* Please provide your email address */}
          Please provide your email adress
        </p>
        {/* {active ? null : (
          <span>
            The email address you filed in already exists,please verify that the
            email belongs to you.
          </span>
        )} */}
        <span>Bind a common email address of yours</span>
        <div className="public-form">
          <div className="resend-code" onClick={() => this.resendCode()}>
            {/* Resend code */}
            {time === 60 || time === 0 ? 'Resend code' : `${time}s`}
          </div>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="account"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                prefix={
                  <img
                    src={require('../../assets/imgs/svg/icon_email.svg').default}
                    alt="email icon"
                  />
                }
                placeholder="Enter your email address"
                // allowClear
              />
            </Form.Item>
            <Form.Item
              name="verifyCode"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                prefix={
                  <img
                    src={require('../../assets/imgs/svg/icon_password.svg').default}
                    alt="password icon"
                  />
                }
                placeholder="Enter your code"
                // allowClear
              />
            </Form.Item>

            <Form.Item>
              <Button
                // loading={store.getState().getLoading}
                type="primary"
                htmlType="submit"
              >
                Sumbit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
