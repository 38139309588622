/*
  *登录组件
  bill_yang 2021.09
*/
import { Button, Form, Input, message } from 'antd';
import md5 from 'js-md5';
import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import { sendSessionAnalytics } from 'untils/tracking';
import store from '../../redux/store';
import { facebookLogin, googleLogin, login } from '../../services/index';
import {
  clearSessionStorage,
  getLocalStorage,
  getSessionStorage,
  openLoginPopup,
  regularCheck,
  saveUserInfo,
  setLocalStorage,
} from '../../untils/auth';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTrueEmail: '', // 邮箱校验
      isTruePassword: '', // 密码校验
    };
  }
  // 忘记密码
  forgotPassword = () => {
    this.props.forgotPassword();
  };
  // 提交
  onFinish = (values) => {
    let params = values;
    if (!regularCheck('email', params.account)) {
      return message.error('Please enter a valid email address');
    }
    params.password = md5(params.password);
    login(params).then((res) => {
      if (res.code) {
        sendSessionAnalytics(res.data.userid);
        // 储存个人信息
        saveUserInfo(res.data);
        // 弹出领卷成功 res.data.newUser
        // if (res.data.gainCoupon !== undefined) {
        //   this.props.gainCoupon();
        // } else {
        //   // 关闭新人活动的注册弹窗
        //   if (this.props.onCancel) {
        //     this.props.onCancel();
        //   }
        // }
        this.props.onCancel();

        // 判断是否google 登录
        if (res.data.type !== undefined) {
          // setLocalStorage('loginType', true);
        }

        store.dispatch({
          type: 'token',
          data: res.data.accessToken,
        });
        setLocalStorage('time', res.data.accessExpire + '000');
        setLocalStorage('userId', res.data.userid);
        // 提示成功
        message.success('Login Successful!');
        let body = document.body;
        body.style.overflow = 'auto';
        openLoginPopup('close');
        // 详情页
        if (getLocalStorage('details') !== null) {
          this.props.history.push('/payDetails');
        }
        if (getLocalStorage('scheduled') !== null) {
          let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
          this.props.history.push(`/searchFlightsPassengers/${id}`);
        }
        // 宠物航班
        if (getSessionStorage('petDetailsLogin') !== null) {
          let data = JSON.parse(getSessionStorage('petDetailsLogin'));
          this.props.history.push({
            pathname: `/petFriendlyFlight/detail/BookingDetails`,
            state: { ...data },
          });
          clearSessionStorage('petDetailsLogin');
        }
        if (getLocalStorage('scheduled') !== null) {
          let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
          this.props.history.push(`/searchFlightsPassengers/${id}`);
        }

        // 去会员填写信息页
        const urlParams = new URL(window.location.href);
        const pathname = urlParams?.pathname;
        if (pathname === '/addCardInformation') {
          this.props.history.push('/membership');
        }

        if (getSessionStorage('chaData')) {
          store.dispatch({
            type: 'charterQuoteSumbit',
          });
        }

        if (getSessionStorage('eptData')) {
          store.dispatch({
            type: 'emptyLegSumbit',
          });
        }
      } else {
        if (res.msg.indexOf('incorrect password') !== -1) {
          message.error(
            'The email and password you entered do not match. Please try again or click "Forgot Password".'
          );
        } else if (res.msg.indexOf("The account doesn't exist") !== -1) {
          message.error("The account doesn't exist. Please Sign Up.");
        } else {
          message.error(res.msg);
        }
      }
      // Modal.warning({
      //   title: '',
      //   content:
      //     'Registered successfully, Please activate it in your mailbox!',
      //   onOk: () => {
      //     this.props.login();
      //   },
      // });
    });
  };
  // 谷歌登录失败
  onFailure = (e) => {
    // message.error('Login failed!');
    console.log(e, 'error');
  };
  // 谷歌登录成功
  onSuccess = (e) => {
    googleLogin(e.tokenId).then((res) => {
      if (res.code) {
        let body = document.body;
        body.style.overflow = 'auto';

        // 提示成功
        message.success('Login Successful!');
        // 返回页面

        if (!res.data.userid) {
          this.props.boundGoogle(res.data.openId);
          // this.props.history.push(`/bound/${res.data.openId}`);
        } else {
          sendSessionAnalytics(res.data.userid);
          let user = {
            account: res.data.email,
            name: res.data.name,
            surName: res.data.surName,
            phone: res.data.phone,
            phoneCode: res.data.phoneCode,
            countryIso2: res.data.countryIso2,
            country: res.data.country,
            photo: res.data.photo,
            membershipCardType: res.data.membershipCardType,
            membershipShuttleNumber: res.data.membershipShuttleNumber,
          };
          saveUserInfo(user);
          store.dispatch({
            type: 'token',
            data: res.data.accessToken,
          });
          setLocalStorage('userId', res.data.userid);
          setLocalStorage('time', res.data.accessExpire + '000');
          // setLocalStorage('loginType', true);
          openLoginPopup('close');
          // 弹出领卷成功
          if (res.data.newUser) {
            this.props.gainCoupon();
          } else {
            // 关闭新人活动的注册弹窗
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          }
          // 详情页
          if (getLocalStorage('details') !== null) {
            this.props.history.push('/payDetails');
          }
          if (getLocalStorage('scheduled') !== null) {
            let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
            this.props.history.push(`/searchFlightsPassengers/${id}`);
          }
          // 去会员填写信息页
          const urlParams = new URL(window.location.href);
          const pathname = urlParams?.pathname;
          if (pathname === '/addCardInformation') {
            this.props.history.push('/membership');
          }

          if (getSessionStorage('chaData')) {
            store.dispatch({
              type: 'charterQuoteSumbit',
            });
          }

          if (getSessionStorage('eptData')) {
            store.dispatch({
              type: 'emptyLegSumbit',
            });
          }
        }
      } else {
        message.error(res.msg);
      }
    });
  };
  // // 微信登录
  // loginWx = () => {
  //   let url = 'http://airtaxi.airacerchina.cn';
  //   window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wxc8a257521d9de718&redirect_uri=${url}&response_type=code&scope=snsapi_login#wechat_redirect`;
  // };
  // facebook登录
  responseFacebook = (e) => {
    console.log(e, 963);
    if (e.status === 'unknown') {
      return;
    }
    facebookLogin(e.accessToken).then((res) => {
      if (res.code) {
        let body = document.body;
        body.style.overflow = 'auto';

        // 返回页面
        if (!res.data.userid) {
          this.props.facebookLogin(res.data.openId);

          // this.props.history.push(`/bound/${res.data.openId}`);
        } else {
          sendSessionAnalytics(res.data.userid);
          // 提示成功
          message.success('Login Successful!');
          let user = {
            account: res.data.email,
            name: res.data.name,
            surName: res.data.surName,
            phone: res.data.phone,
            phoneCode: res.data.phoneCode,
            countryIso2: res.data.countryIso2,
            country: res.data.country,
            photo: res.data.photo,
            membershipCardType: res.data.membershipCardType,
            membershipShuttleNumber: res.data.membershipShuttleNumber,
          };
          saveUserInfo(user);
          store.dispatch({
            type: 'token',
            data: res.data.accessToken,
          });
          setLocalStorage('userId', res.data.userid);
          setLocalStorage('time', res.data.accessExpire + '000');
          // setLocalStorage('loginType', true);
          openLoginPopup('close');
          // 弹出领卷成功
          if (res.data.newUser) {
            this.props.gainCoupon();
          } else {
            // 关闭新人活动的注册弹窗
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          }
          // 详情页
          if (getLocalStorage('details') !== null) {
            this.props.history.push('/payDetails');
          }
          if (getLocalStorage('scheduled') !== null) {
            let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
            this.props.history.push(`/searchFlightsPassengers/${id}`);
          }
          // 去会员填写信息页
          const urlParams = new URL(window.location.href);
          const pathname = urlParams?.pathname;
          if (pathname === '/addCardInformation') {
            this.props.history.push('/membership');
          }

          if (getSessionStorage('chaData')) {
            store.dispatch({
              type: 'charterQuoteSumbit',
            });
          }

          if (getSessionStorage('eptData')) {
            store.dispatch({
              type: 'emptyLegSumbit',
            });
          }
        }
      } else {
        message.error(res.msg);
      }
    });
  };
  render() {
    return (
      <div className="public-form signin">
        <p className="form-title">Welcome! Enter the information to get started</p>
        <Form
          name="basics"
          initialValues={{
            remember: true,
          }}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="account"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              prefix={
                <img
                  src={require('../../assets/imgs/svg/icon_email.svg').default}
                  alt="email icon"
                />
              }
              placeholder="Email Address"
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.Password
              prefix={
                <img
                  src={require('../../assets/imgs/svg/icon_password.svg').default}
                  alt="password icon"
                />
              }
              placeholder="Password"
            />
          </Form.Item>
          <p className="forgot-password">
            <button type="button" onClick={() => this.forgotPassword()}>
              <u>Forgot Password?</u>
            </button>
          </p>
          <Form.Item>
            <Button type="primary" loading={store.getState().getLoading} htmlType="submit">
              Sign in
            </Button>
          </Form.Item>
        </Form>
        <div className="or">or</div>
        <div className="other-login">
          <p>
            <GoogleLogin
              clientId="437987232398-s5egldv4ao6rpvv8f61391rsluk1q928.apps.googleusercontent.com"
              buttonText="Login"
              render={(renderProps) => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  Sign in with Google
                </button>
              )}
              onSuccess={(e) => this.onSuccess(e)}
              onFailure={(e) => this.onFailure(e)}
              cookiePolicy={'single_host_origin'}
            />
            <i className="bg-image google"></i>
            <span>Continue with Google</span>
          </p>
          {/*<p>*/}
          {/*  <i className="bg-image facebook"></i>*/}
          {/*  <span>Continue with Facebook</span>*/}
          {/*  <FacebookLogin*/}
          {/*    appId="632352834436211"*/}
          {/*    fields="name,email,picture"*/}
          {/*    autoLoad={false}*/}
          {/*    callback={this.responseFacebook}*/}
          {/*  />*/}
          {/*</p>*/}
        </div>
      </div>
    );
  }
}
export default SignIn;
