/*
  *公共方法
  bill_yang 2021.09
*/
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { getLogin } from '../redux/actions/getLogin';
import store from '../redux/store';
// 获取sessionStorage
export const getSessionStorage = (name) => {
  return sessionStorage.getItem(name);
};
export const $ = (id) => {
  return document.getElementById(id);
};
// 缓存sessionStorage
export const setSessionStorage = (name, data) => {
  sessionStorage.setItem(name, data);
};

// 清除sessionStorage
export const clearSessionStorage = (name) => {
  sessionStorage.removeItem(name);
};

// 清除sessionStorage所有
export const clearSessionStorageAll = () => {
  sessionStorage.clear();
};

// 获取LocalStorage
export const getLocalStorage = (name) => {
  return localStorage.getItem(name);
};

// 缓存LocalStorage
export const setLocalStorage = (name, data) => {
  localStorage.setItem(name, data);
};

// 清除LocalStorage
export const clearLocalStorage = (name) => {
  localStorage.removeItem(name);
};

// 清除LocalStorage所有
export const clearLocalStorageAll = () => {
  // localStorage.clear();
  let keys = [
    'time',
    'user',
    'userId',
    'acceptCookies',
    'from',
    'to',
    'loginType',
    'details',
    'member',
    'scheduled',
    'token',
  ];
  keys.map((name) => clearLocalStorage(name));
};

// 判断是否微信登录
export const isWeiXin = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
};

// 正则校验
export const regularCheck = (name, value) => {
  // 邮箱
  let email = /^[A-Za-z0-9]+(\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  // 纯数字3位
  let number3 = /^\d{3}$/;
  let passengerWeight = /^\d{1,3}$/;

  // 纯数字16位
  let number16 = /^\d{16}$/;
  //用户名 匹配字母，数字，特殊字符，必须包含字母
  // let userName = /^(?=.*[a-zA-Z])[A-Za-z\d.@$!%*#_~?&^]{1,20}/;
  let userName = /^[a-zA-Z\s]{1,20}$/;
  // passenger name
  let passengerName = /^[a-zA-Z]{1,16}$/;
  // 注册名字
  //匹配由26个英文字母组成的字符串
  let registerName = /^([a-zA-Z ]+|[\u4e00-\u9fa5]+)$/;
  // 正整数6-11位
  let numberPhone = /^\d{6,11}$/; //匹配由6-11数字
  // 密码
  let password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/; //至少8个字符，至少1个大写字母，1个小写字母，1个数字和1个特殊字符

  switch (name) {
    case 'email':
      return email.test(value) ? true : false;
    case 'registerName':
      return registerName.test(value) ? true : false;
    case 'numberPhone':
      return numberPhone.test(value) ? true : false;
    case 'password':
      return password.test(value) ? true : false;
    case 'number3':
      return number3.test(value) ? true : false;
    case 'number16':
      return number16.test(value) ? true : false;
    case 'passengerName':
      return passengerName.test(value) ? true : false;
    case 'userName':
      return userName.test(value) ? true : false;
    case 'passengerWeight':
      return passengerWeight.test(value) ? true : false;
    default:
      break;
  }
};

// 页面滚动指定距离
export const roll = (number) => {
  window.scrollTo({ top: number, behavior: 'smooth' });
};

// 防抖
export const debounce = (fun, delay) => {
  let timeout;
  return function (value) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fun(value);
    }, delay);
  };
};

// 节流
export const throttle = (fun, delay) => {
  let canRun = true;
  return function (value) {
    if (!canRun) return;
    canRun = false;
    setTimeout(() => {
      fun(value);
      canRun = true;
    }, delay);
  };
};

// 银行卡加密
export const encryption = (x) => {
  return x.replace(/^(\d{8})(\d{4})(\d{4})$/, `**** **** **** $3`);
};
// 时区
export const timeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// 浏览器可视区域高度
export const getViewPortHeight = () => {
  return document.documentElement.clientHeight || document.body.clientHeight;
};
// utc
export const utc = (dete) => {
  return Date.UTC(
    dete.getUTCFullYear(),
    dete.getUTCMonth(),
    dete.getUTCDate(),
    dete.getUTCHours(),
    dete.getUTCMinutes(),
    dete.getUTCSeconds(),
    dete.getUTCMilliseconds()
  );
};

// 转换飞行时间
export const switchTime = (time) => {
  if (time >= 60) {
    if (time % 60 !== 0) {
      return `${parseInt(time / 60)}h ${time % 60}m`;
    }
    return `${parseInt(time / 60)}h`;
  }
  return `${time}m`;
};

// 判断是否有token弹出登录框
export const openLoginPopup = (type) => {
  return store.dispatch(getLogin(type));
};
// 金额数字三位一个逗号
export const divisionNumber = (number) => {
  number = Math.ceil(number);
  let str = Number(number).toString();
  let reg = str.indexOf('.') > -1 ? /\B(?=(\d{3})+\.)/g : /\B(?=(\d{3})+$)/g;
  return str.replace(reg, ',');
};

// 获取url参数
export const getQueryVariable = (variable) => {
  let query = window.location.search.substring(1);
  let vars = query.split('&');

  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

/**
 * 世界协调时间(UTC)转本地时间(处理接口数据)
 */
export const formatDateHandle = (datas, timezone) => {
  if (!datas) return;
  let newDate = momentTimezone
    .tz(momentTimezone.utc(datas), timezone)
    .format('YYYY-MM-DD HH:mm:ss');
  return newDate;
};

/**
 * 本地时间转世界协调时间(UTC)(发送数据)
 */
export const formatUtcDate = (date, timezone) => {
  if (!date) return;
  if (!timezone) return moment(date).format('YYYY-MM-DD HH:mm:ss');
  return moment.tz(date, timezone).utc().format('YYYY-MM-DD HH:mm:ss');
};

//处理city数据
export const formatCity = (cityName) => {
  if (cityName) return JSON.parse(cityName)[0];
  return cityName;
};

//判断机型
export const getModelCategory = (type, data) => {
  if (type === 'Helicopter') {
    data.moduleClassName = 'type_helicopter';
    data.Aircraft.ModuleColor = '#6F5BF8';
  } else if (type === 'Turbo Prop' || type === 'Piston') {
    data.moduleClassName = 'type_turboprop';
    data.Aircraft.ModuleColor = '#17C7FF';
  } else if (type === 'Very Light Jet' || type === 'Light Jet') {
    data.moduleClassName = 'type_very';
    data.Aircraft.ModuleColor = '#F7C102';
  } else if (type === 'Midsize Jet' || type === 'Super Midsize Jet') {
    data.moduleClassName = 'type_mid';
    data.Aircraft.ModuleColor = '#2DCA73';
  } else if (type === 'Heavy Jet') {
    data.moduleClassName = 'type_heavy';
    data.Aircraft.ModuleColor = '#FF4E4B';
  } else if (type === 'Ultra Long Range Jet') {
    data.moduleClassName = 'type_ultra';
    data.Aircraft.ModuleColor = '#FF8E65';
  } else if (type === 'VIP Airliner') {
    data.moduleClassName = 'type_airliner';
    data.Aircraft.ModuleColor = '#252C6D';
  } else {
    data.moduleClassName = 'type_others';
    data.Aircraft.ModuleColor = '#0063DB';
  }
};

// 保存用户信息
export const saveUserInfo = (data) => {
  let { account, name, surName, phone, phoneCode, country, countryIso2, photo } = data;
  let user = {
    account,
    name,
    surName: surName,
    phone: phone,
    phoneCode: phoneCode,
    country: country,
    countryIso2: countryIso2,
    photo: photo,
  };
  if (data.surName && !data.name) user.name = data.surName;
  if (data.membershipCardType) user.membershipCardType = data.membershipCardType;
  if (data.membershipShuttleNumber) user.membershipShuttleNumber = data.membershipShuttleNumber;
  setLocalStorage('user', JSON.stringify(user));
};

// 判断用户选择的出发时间是否满足晚于实际时间的一个小时
export const expireDate = (date, nowDate) => {
  if (!date) return false;
  nowDate = new Date(nowDate).getTime();
  const departureDate = new Date(date).getTime();
  if (departureDate - nowDate > 3600000) {
    return true;
  } else {
    return false;
  }
};

export const camalize = (str) => {
  if (!str) return str;
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
};

// 自定义排序
export const customSort = ({ data, sortBy, sortField }) => {
  if (!data) return false;
  const sortByObject = sortBy.reduce(
    (obj, item, index) => ({
      ...obj,
      [item]: index,
    }),
    {}
  );
  return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
};

// 机场大小配置
export const airportSize = (data) => {
  if (!data) return false;
  for (let item of data) {
    if (item.airportType !== undefined) {
      if (item.airportType === 'Large_Airport') {
        item['airportSize'] = 1;
      } else if (item.airportType === 'Medium_Airport') {
        item['airportSize'] = 2;
      } else if (item.airportType === 'Small_Airport') {
        item['airportSize'] = 3;
      } else {
        item['airportSize'] = 4;
      }
    }
  }
  return data;
};

// 机场排序
export const airportSort = (data) => {
  data.sort((a, b) => {
    if (!a.hot) a.hot = 0;
    if (!b.hot) b.hot = 0;
    if (a.hot === b.hot) {
      return a.airportSize - b.airportSize;
    }
    return b.hot - a.hot;
  });
};
// 时间截取
export const timeSlice = (str) => {
  if (!str) return;
  return str.slice(5, 10).split('-').join('.');
};

function generateNestedFormData(data, formData, parentKey) {
  if (Array.isArray(data)) {
    data.forEach((value, index) => {
      const key = `${parentKey}[${index}]`;
      if (typeof value === 'object') {
        generateNestedFormData(value, formData, key);
      } else {
        formData.append(key, value);
      }
    });
  } else if (typeof data === 'object') {
    for (let key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const nestedKey = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof value === 'object') {
          generateNestedFormData(value, formData, nestedKey);
        } else {
          formData.append(nestedKey, value);
        }
      }
    }
  } else {
    formData.append(parentKey, data);
  }
}

export function generateFormData(data) {
  const formData = new FormData();
  generateNestedFormData(data, formData, '');
  return formData;
}

export function base64ToBlob(base64) {
  const base64Data = base64.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
  const binaryString = window.atob(base64Data);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: 'pdf' });
  return blob;
}
