import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import metaData from './data.json';

const siteUrl = process.env.REACT_APP_SITE_URL || 'https://localhost:3000';
const fullImageUrl = new URL('/imgs/webp/home/seo.webp', siteUrl);
const getDecodedBasePath = (fullPath) => {
  const purePath = decodeURIComponent(fullPath.split(/[?#]/)[0]);
  const match = purePath.match(/^\/+([^/]+)/);
  return match ? `/${match[1]}` : '/';
};

const HeaderMeta = ({ type, children }) => {
  const seoMetaData = metaData[type] || metaData['default'];
  const canonicalUrl = new URL(getDecodedBasePath(window.location.pathname), siteUrl);

  return (
    <Helmet>
      <title>{seoMetaData.title}</title>
      <meta name="description" content={seoMetaData.description} />
      <meta name="keywords" content={seoMetaData.keywords.join(',')} />
      <meta property="og:image" content={fullImageUrl} />
      <link rel="canonical" href={canonicalUrl} />
      {children}
    </Helmet>
  );
};

HeaderMeta.propTypes = {
  type: PropTypes.oneOf(Object.keys(metaData)).isRequired,
  children: PropTypes.node,
};

export default HeaderMeta;
