/*
  *请求
  bill_yang 2021.06
*/
import { message } from 'antd';
import axios from 'axios';
import qs from 'qs';
import { BrowserRouter } from 'react-router-dom';
import store from '../redux/store';
import {
  clearLocalStorageAll,
  getLocalStorage,
  getSessionStorage,
  openLoginPopup,
  setSessionStorage,
  utc,
} from './auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 3000000,
});
// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    store.dispatch({
      type: 'true',
    });
    let nowTime = utc(new Date());
    let time = !getLocalStorage('time') ? '' : Number(getLocalStorage('time'));
    if (time !== '' && time <= nowTime) {
      const router = new BrowserRouter();
      message.error({
        content: 'login timeout',
      });
      clearLocalStorageAll();
      openLoginPopup('open');
      setSessionStorage('coupons', true);
      router.history.push('/');
      return;
    }
    // api添加识别经销商
    let sourceId = getSessionStorage('sourceId');
    let symbol = '?';
    if (config.url.indexOf('?') > -1) symbol = '&';
    if (sourceId) {
      config.url = config.url + `${symbol}distributorID=${sourceId}`;
    }

    config.data = qs.stringify(config.data);
    config.headers['content-type'] = 'application/x-www-form-urlencoded';

    config.headers.Authorization = !getLocalStorage('token') ? '' : getLocalStorage('token');
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error);
    return Promise.reject(error);
  }
);
// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    store.dispatch({ type: 'false' });
    // 对响应数据做点什么
    const res = response;
    if (res.status === 200) {
      return response.data;
    }
  },
  (error) => {
    store.dispatch({ type: 'false' });
    let { response } = error;
    if (response && response.status === 401) {
      const router = new BrowserRouter();
      message.error({
        content: 'login timeout',
      });
      clearLocalStorageAll();
      openLoginPopup('open');
      setSessionStorage('coupons', true);
      router.history.push('/');
    } else {
      // 对响应错误做点什么
      // Toast.fail(error.message);
    }
    return Promise.reject(error);
  }
);

export const secondaryInstance = axios.create({
  baseURL: process.env.REACT_APP_API2_URL,
  timeout: 3000000,
});

export default instance;
